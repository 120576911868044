@keyframes lungs-scale {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes lung-left {
    0% {
        d: path(
            'M184.5,552.6a14.32,14.32,0,0,1-10.1-4.2c-19.7-18.1-27.3-82-7.2-134l.3-.7c11.6-30.1,24.8-64.1,40-90.2,17.8-30.6,35.8-45.5,54.8-45.5h0c15.8,0,20.7,15.2,22.9,26.6,3.3,16.5,3.5,41.1,3.5,69.2,0,9.5.7,21.1,1.4,33.3,1.8,31.2,3.9,66.5-4.3,83.2-6.5,13.3-18.2,31.2-44.2,33.2-10.6.8-20.9,8.5-30.9,15.8C201.9,545.9,192.8,552.6,184.5,552.6ZM262.2,283h0c-38.9,0-67.3,73.5-90.1,132.6l-.3.7c-20.3,52.7-10.6,113.3,6,128.5,7.3,6.7,16.1.8,29.9-9.4,10-7.4,21.4-15.9,33.5-16.8,23.2-1.8,33.7-17.2,40.1-30.4,7.6-15.4,5.5-50.1,3.8-80.7-.7-12.3-1.4-24-1.4-33.6,0-27.8-.3-52.1-3.4-68.2C276.3,285.6,268.5,283,262.2,283Z'
        );
        transform: translate(-155.37 -258.56);
    }
    5% {
        d: path(
            'M183.55,556.21a17.9,17.9,0,0,1-11.25-3.86c-23.54-18.06-27.88-82.67-9.3-138.26,12.89-38.69,26.79-70.13,41.33-93.42,19.11-30.61,38.92-46.14,58.88-46.15h0c26,0,28.53,37.39,30.42,98.4h0c.17,5.2.41,11,.68,17.1,1.5,35,3.55,82.91-6.76,104-5,10.19-15.32,31.38-44.9,36.47-10,1.71-20,7.71-29.69,13.51C202.59,550.24,192.61,556.21,183.55,556.21Zm79.67-276.69h0c-43.64,0-78.54,85.32-95.46,136.15-17.67,52.85-14.05,116.1,7.59,132.72,9,6.92,21.09-.29,35.06-8.65,10.08-6,20.51-12.28,31.41-14.15,23.78-4.09,33.88-18.63,41.26-33.73,9.75-20,7.73-67.17,6.25-101.62-.26-6.14-.51-11.94-.68-17.17h0C286.85,315.07,284.65,279.52,263.22,279.52Z'
        );
        transform: translate(-151.44 -258.52);
    }
    10% {
        d: path(
            'M182.7,559.78a22.81,22.81,0,0,1-12.77-3.65c-13.31-8.66-21.07-28.44-22.44-57.19-1.2-25.26,2.84-56.35,11.11-85.29,10.19-35.86,25.71-70.93,42.59-96.23C221.46,287,243.17,271,264,270.94h0c26.91,0,30.58,38.38,34.34,101,2.16,35.44,3.82,98.92-9.27,125.78-5.79,11.9-15.49,31.81-45.57,39.68-8.71,2.28-17.75,6.64-26.48,10.85C204.82,554.13,193.1,559.78,182.7,559.78ZM264,275.94h0c-24.06,0-45.16,24.07-58.62,44.25-16.6,24.89-31.89,59.45-41.94,94.83-8.13,28.44-12.11,58.94-10.92,83.68,1.27,26.69,8.43,45.6,20.17,53.24,10.72,7,26-.39,42.17-8.19,9-4.32,18.22-8.78,27.39-11.18,27.86-7.3,36.58-25.19,42.35-37,4.35-8.92,13.91-38.81,8.77-123.29h0c-1.76-29.4-3.6-55.13-8.17-72.36C280.78,283.33,274.24,275.94,264,275.94Z'
        );
        transform: translate(-147.28 -258.52);
    }
    15% {
        d: path(
            'M182.48,563.39a30.47,30.47,0,0,1-15.07-3.57c-15.36-8.54-23.61-28.34-24.53-58.85-.92-30.23,5.57-64.46,11.17-87.84,16.8-70.12,63.83-145.81,110.53-145.84h0c27.82,0,32.67,39.37,38.28,103.61,3.22,36.88,1.48,101.74-12.46,130.43l-.08.17c-6.59,13.55-15.6,32.09-46.2,42.71-5.73,2-11.8,4.44-17.67,6.81C211.33,557.13,195.81,563.39,182.48,563.39Zm82.12-291.1h0c-20,0-42.2,15.89-62.62,44.7-19,26.75-34.65,62.22-43.06,97.3-5.53,23.09-11.93,56.85-11,86.53.87,28.62,8.26,47,22,54.63s34.51-.9,54.76-9.07c5.92-2.39,12.05-4.86,17.9-6.89,28.65-9.94,37.14-27.41,43.34-40.17l.08-.18c8.15-16.75,17.25-67.48,12-127.81h0c-2.54-29.06-5.26-56.62-10.54-74.43C282.32,279.88,275.3,272.29,264.6,272.29Z'
        );
        transform: translate(-142.79 -258.52);
    }
    20% {
        d: path(
            'M182.7,559.78a22.81,22.81,0,0,1-12.77-3.65c-13.31-8.66-21.07-28.44-22.44-57.19-1.2-25.26,2.84-56.35,11.11-85.29,10.19-35.86,25.71-70.93,42.59-96.23C221.46,287,243.17,271,264,270.94h0c26.91,0,30.58,38.38,34.34,101,2.16,35.44,3.82,98.92-9.27,125.78-5.79,11.9-15.49,31.81-45.57,39.68-8.71,2.28-17.75,6.64-26.48,10.85C204.82,554.13,193.1,559.78,182.7,559.78ZM264,275.94h0c-24.06,0-45.16,24.07-58.62,44.25-16.6,24.89-31.89,59.45-41.94,94.83-8.13,28.44-12.11,58.94-10.92,83.68,1.27,26.69,8.43,45.6,20.17,53.24,10.72,7,26-.39,42.17-8.19,9-4.32,18.22-8.78,27.39-11.18,27.86-7.3,36.58-25.19,42.35-37,4.35-8.92,13.91-38.81,8.77-123.29h0c-1.76-29.4-3.6-55.13-8.17-72.36C280.78,283.33,274.24,275.94,264,275.94Z'
        );
        transform: translate(-147.28 -258.52);
    }
    25% {
        d: path(
            'M183.55,556.21a17.9,17.9,0,0,1-11.25-3.86c-23.54-18.06-27.88-82.67-9.3-138.26,12.89-38.69,26.79-70.13,41.33-93.42,19.11-30.61,38.92-46.14,58.88-46.15h0c26,0,28.53,37.39,30.42,98.4h0c.17,5.2.41,11,.68,17.1,1.5,35,3.55,82.91-6.76,104-5,10.19-15.32,31.38-44.9,36.47-10,1.71-20,7.71-29.69,13.51C202.59,550.24,192.61,556.21,183.55,556.21Zm79.67-276.69h0c-43.64,0-78.54,85.32-95.46,136.15-17.67,52.85-14.05,116.1,7.59,132.72,9,6.92,21.09-.29,35.06-8.65,10.08-6,20.51-12.28,31.41-14.15,23.78-4.09,33.88-18.63,41.26-33.73,9.75-20,7.73-67.17,6.25-101.62-.26-6.14-.51-11.94-.68-17.17h0C286.85,315.07,284.65,279.52,263.22,279.52Z'
        );
        transform: translate(-151.44 -258.52);
    }
    100% {
        d: path(
            'M184.53,552.64a14.5,14.5,0,0,1-10.13-4.16c-19.72-18.14-27.34-82-7.23-134l.26-.67c11.61-30.06,24.77-64.13,40-90.24C225.22,293,243.15,278.06,262.2,278h0c15.84,0,20.68,15.19,22.94,26.61,3.28,16.54,3.55,41.12,3.55,69.19,0,9.47.67,21.06,1.38,33.33,1.81,31.19,3.86,66.54-4.28,83.15-6.51,13.31-18.19,31.23-44.24,33.23-10.63.82-20.92,8.46-30.87,15.84C201.87,545.93,192.84,552.64,184.53,552.64ZM262.21,283h0c-38.9,0-67.3,73.52-90.11,132.57l-.26.67c-20.34,52.66-10.57,113.33,5.95,128.52,7.32,6.74,16.14.8,29.93-9.43,10-7.44,21.4-15.87,33.47-16.8,23.23-1.79,33.65-17.21,40.13-30.45,7.56-15.43,5.55-50.09,3.78-80.66-.72-12.35-1.39-24-1.39-33.62,0-27.8-.26-52.13-3.45-68.22C276.29,285.62,268.53,283,262.21,283Z'
        );
        transform: translate(-155.34 -258.52);
    }
    // 100% {
    //     d: path(
    //         'M184.5,552.6a14.32,14.32,0,0,1-10.1-4.2c-19.7-18.1-27.3-82-7.2-134l.3-.7c11.6-30.1,24.8-64.1,40-90.2,17.8-30.6,35.8-45.5,54.8-45.5h0c15.8,0,20.7,15.2,22.9,26.6,3.3,16.5,3.5,41.1,3.5,69.2,0,9.5.7,21.1,1.4,33.3,1.8,31.2,3.9,66.5-4.3,83.2-6.5,13.3-18.2,31.2-44.2,33.2-10.6.8-20.9,8.5-30.9,15.8C201.9,545.9,192.8,552.6,184.5,552.6ZM262.2,283h0c-38.9,0-67.3,73.5-90.1,132.6l-.3.7c-20.3,52.7-10.6,113.3,6,128.5,7.3,6.7,16.1.8,29.9-9.4,10-7.4,21.4-15.9,33.5-16.8,23.2-1.8,33.7-17.2,40.1-30.4,7.6-15.4,5.5-50.1,3.8-80.7-.7-12.3-1.4-24-1.4-33.6,0-27.8-.3-52.1-3.4-68.2C276.3,285.6,268.5,283,262.2,283Z'
    //     );
    //     transform: translate(-155.37 -258.56);
    // }
}

@keyframes lung-right {
    0% {
        d: path(
            'M446.7,552.6c-8.3,0-17.3-6.7-26.2-13.2-10-7.4-20.2-15-30.9-15.8-26.1-2-37.7-19.9-44.2-33.2-8.1-16.6-6.1-52-4.3-83.2.7-12.3,1.4-23.9,1.4-33.3,0-28.1.3-52.7,3.5-69.2,2.3-11.4,7.1-26.6,22.9-26.6h0c19,0,37,14.9,54.8,45.5,15.2,26.1,28.4,60.2,40,90.2l.3.7c20.1,52,12.5,115.9-7.2,134A14.63,14.63,0,0,1,446.7,552.6ZM369,283c-6.3,0-14.1,2.6-18,22.6-3.2,16.1-3.4,40.4-3.4,68.2,0,9.6-.7,21.3-1.4,33.6-1.8,30.6-3.8,65.2,3.8,80.7,6.5,13.2,16.9,28.7,40.1,30.4,12.1.9,23.4,9.4,33.5,16.8,13.8,10.2,22.6,16.2,29.9,9.4,16.5-15.2,26.3-75.9,6-128.5l-.3-.7C436.3,356.6,407.9,283.1,369,283Z'
        );
        transform: translate(-155.37 -258.56);
    }
    5% {
        d: path(
            'M447.62,556.21c-9.06,0-19-6-29.41-12.18-9.69-5.8-19.71-11.8-29.69-13.51-29.58-5.09-39.93-26.28-44.9-36.47-10.31-21.12-8.26-69-6.76-104,.26-6.12.51-11.91.68-17.11h0c1.89-61,4.37-98.4,30.41-98.4h0c20,0,39.77,15.54,58.88,46.15,14.54,23.29,28.44,54.73,41.32,93.43,18.59,55.58,14.25,120.19-9.29,138.25A17.93,17.93,0,0,1,447.62,556.21ZM368,279.52c-21.44,0-23.63,35.55-25.42,93.55h0c-.17,5.24-.42,11-.68,17.18-1.48,34.45-3.5,81.63,6.25,101.61,7.38,15.1,17.48,29.64,41.26,33.73,10.9,1.87,21.33,8.11,31.41,14.15,14,8.36,26,15.58,35.06,8.65,21.64-16.62,25.26-79.87,7.59-132.71C446.51,364.84,411.61,279.55,368,279.52Z'
        );
        transform: translate(-151.44 -258.52);
    }
    10% {
        d: path(
            'M448.48,559.78c-10.41,0-22.12-5.65-34.31-11.53-8.74-4.21-17.77-8.57-26.48-10.85-30.09-7.87-39.78-27.78-45.58-39.68-13.08-26.86-11.42-90.34-9.26-125.78h0c3.75-62.61,7.43-101,34.34-101h0C388,271,409.72,287,430,317.42c16.88,25.3,32.4,60.37,42.59,96.23,8.26,28.94,12.31,60,11.11,85.29-1.38,28.75-9.14,48.53-22.44,57.19A22.81,22.81,0,0,1,448.48,559.78ZM367.19,275.94c-10.26,0-16.79,7.38-21.18,23.94-4.58,17.23-6.41,43-8.17,72.36h0c-5.14,84.48,4.42,114.37,8.77,123.29,5.77,11.85,14.48,29.74,42.35,37,9.17,2.4,18.43,6.86,27.38,11.18,16.18,7.8,31.46,15.17,42.18,8.19,11.73-7.64,18.9-26.55,20.17-53.24,1.18-24.74-2.8-55.24-10.92-83.68-10-35.38-25.34-69.94-41.94-94.83C412.37,300,391.27,276,367.21,275.94Z'
        );
        transform: translate(-147.28 -258.52);
    }
    15% {
        d: path(
            'M448.7,563.39c-13.33,0-28.85-6.26-44-12.37-5.87-2.37-11.94-4.82-17.67-6.81-30.61-10.62-39.62-29.17-46.2-42.71l-.08-.17c-13.95-28.69-15.68-93.55-12.46-130.44,5.61-64.22,10.47-103.6,38.28-103.6h0c46.7,0,93.74,75.72,110.54,145.84,5.6,23.38,12.09,57.61,11.17,87.84-.92,30.51-9.18,50.31-24.53,58.85A30.47,30.47,0,0,1,448.7,563.39Zm-82.12-291.1c-10.7,0-17.72,7.59-22.76,24.61-5.28,17.81-8,45.37-10.54,74.43h0c-3.17,36.32-1.55,100,12,127.81l.09.18c6.2,12.75,14.69,30.23,43.33,40.17,5.86,2,12,4.5,17.91,6.89,20.24,8.17,41.18,16.62,54.75,9.07,13.72-7.63,21.11-26,22-54.63.9-29.68-5.5-63.44-11-86.53-8.41-35.08-24.1-70.55-43.06-97.3-20.43-28.81-42.66-44.69-62.62-44.7Z'
        );
        transform: translate(-142.79 -258.52);
    }
    20% {
        d: path(
            'M448.48,559.78c-10.41,0-22.12-5.65-34.31-11.53-8.74-4.21-17.77-8.57-26.48-10.85-30.09-7.87-39.78-27.78-45.58-39.68-13.08-26.86-11.42-90.34-9.26-125.78h0c3.75-62.61,7.43-101,34.34-101h0C388,271,409.72,287,430,317.42c16.88,25.3,32.4,60.37,42.59,96.23,8.26,28.94,12.31,60,11.11,85.29-1.38,28.75-9.14,48.53-22.44,57.19A22.81,22.81,0,0,1,448.48,559.78ZM367.19,275.94c-10.26,0-16.79,7.38-21.18,23.94-4.58,17.23-6.41,43-8.17,72.36h0c-5.14,84.48,4.42,114.37,8.77,123.29,5.77,11.85,14.48,29.74,42.35,37,9.17,2.4,18.43,6.86,27.38,11.18,16.18,7.8,31.46,15.17,42.18,8.19,11.73-7.64,18.9-26.55,20.17-53.24,1.18-24.74-2.8-55.24-10.92-83.68-10-35.38-25.34-69.94-41.94-94.83C412.37,300,391.27,276,367.21,275.94Z'
        );
        transform: translate(-147.28 -258.52);
    }
    25% {
        d: path(
            'M447.62,556.21c-9.06,0-19-6-29.41-12.18-9.69-5.8-19.71-11.8-29.69-13.51-29.58-5.09-39.93-26.28-44.9-36.47-10.31-21.12-8.26-69-6.76-104,.26-6.12.51-11.91.68-17.11h0c1.89-61,4.37-98.4,30.41-98.4h0c20,0,39.77,15.54,58.88,46.15,14.54,23.29,28.44,54.73,41.32,93.43,18.59,55.58,14.25,120.19-9.29,138.25A17.93,17.93,0,0,1,447.62,556.21ZM368,279.52c-21.44,0-23.63,35.55-25.42,93.55h0c-.17,5.24-.42,11-.68,17.18-1.48,34.45-3.5,81.63,6.25,101.61,7.38,15.1,17.48,29.64,41.26,33.73,10.9,1.87,21.33,8.11,31.41,14.15,14,8.36,26,15.58,35.06,8.65,21.64-16.62,25.26-79.87,7.59-132.71C446.51,364.84,411.61,279.55,368,279.52Z'
        );
        transform: translate(-151.44 -258.52);
    }
    100% {
        d: path(
            'M446.65,552.64c-8.31,0-17.34-6.71-26.16-13.25-9.95-7.38-20.24-15-30.87-15.84-26.06-2-37.73-19.92-44.24-33.23-8.14-16.61-6.09-52-4.28-83.15.71-12.27,1.38-23.86,1.38-33.33,0-28.07.27-52.65,3.55-69.19C348.29,293.23,353.13,278,369,278h0c19,0,37,14.91,54.8,45.53,15.19,26.11,28.36,60.17,40,90.23l.26.68c20.11,52,12.49,115.86-7.23,134A14.5,14.5,0,0,1,446.65,552.64ZM369,283c-6.32,0-14.08,2.58-18,22.58-3.19,16.09-3.45,40.42-3.45,68.22,0,9.61-.67,21.27-1.39,33.62-1.77,30.57-3.78,65.23,3.78,80.66,6.48,13.24,16.89,28.66,40.13,30.45,12.07.93,23.44,9.36,33.47,16.8,13.78,10.23,22.61,16.17,29.92,9.43,16.52-15.19,26.3-75.86,6-128.52l-.27-.68C436.27,356.55,407.88,283.07,369,283Z'
        );
        transform: translate(-155.34 -258.52);
    }
    // 100% {
    //     d: path(
    //         'M446.7,552.6c-8.3,0-17.3-6.7-26.2-13.2-10-7.4-20.2-15-30.9-15.8-26.1-2-37.7-19.9-44.2-33.2-8.1-16.6-6.1-52-4.3-83.2.7-12.3,1.4-23.9,1.4-33.3,0-28.1.3-52.7,3.5-69.2,2.3-11.4,7.1-26.6,22.9-26.6h0c19,0,37,14.9,54.8,45.5,15.2,26.1,28.4,60.2,40,90.2l.3.7c20.1,52,12.5,115.9-7.2,134A14.63,14.63,0,0,1,446.7,552.6ZM369,283c-6.3,0-14.1,2.6-18,22.6-3.2,16.1-3.4,40.4-3.4,68.2,0,9.6-.7,21.3-1.4,33.6-1.8,30.6-3.8,65.2,3.8,80.7,6.5,13.2,16.9,28.7,40.1,30.4,12.1.9,23.4,9.4,33.5,16.8,13.8,10.2,22.6,16.2,29.9,9.4,16.5-15.2,26.3-75.9,6-128.5l-.3-.7C436.3,356.6,407.9,283.1,369,283Z'
    //     );
    //     transform: translate(-155.37 -258.56);
    // }
}

@keyframes fill-left {
    0% {
        d: path('');
    }
    25% {
        d: path(
            'M184.66,544.48a6.64,6.64,0,0,1-4.73-2c-7.79-7.16-14-26.05-15.83-48.12-2.16-26.16,1.63-53.48,10.69-76.93l.26-.67c22.46-58.15,50.42-130.51,87.15-130.54,5.43,0,11.45,2.38,14.94,20,3.13,15.81,3.39,40,3.39,67.59,0,9.71.67,21.41,1.39,33.8,1.67,28.69,3.74,64.4-3.45,79.09-6.11,12.48-15.9,27-37.54,28.68-13,1-24.73,9.72-35.11,17.42C198.1,538.56,190.11,544.48,184.66,544.48Z'
        );
    }
    30% {
        d: path(
            'M184.66,544.48a6.64,6.64,0,0,1-4.73-2c-7.79-7.16-14-26.05-15.83-48.12-2.16-26.16,1.63-53.48,10.69-76.93l.26-.67c22.46-58.15,50.42-130.51,87.15-130.54,5.43,0,11.45,2.38,14.94,20,3.13,15.81,3.39,40,3.39,67.59,0,9.71.67,21.41,1.39,33.8,1.67,28.69,3.74,64.4-3.45,79.09-6.11,12.48-15.9,27-37.54,28.68-13,1-24.73,9.72-35.11,17.42C198.1,538.56,190.11,544.48,184.66,544.48Z'
        );
    }
    100% {
        d: path('');
    }
}

@keyframes fill-right {
    0% {
        d: path('');
    }
    25% {
        d: path(
            'M446.52,544.48c-5.45,0-13.44-5.92-21.16-11.65-10.38-7.7-22.14-16.42-35.12-17.42-21.63-1.66-31.42-16.2-37.53-28.68-7.19-14.69-5.12-50.4-3.46-79.09.72-12.39,1.4-24.09,1.4-33.8,0-27.63.26-51.78,3.39-67.59,3.49-17.66,9.51-20,14.93-20,36.74,0,64.69,72.39,87.16,130.53l.26.68c9.06,23.45,12.85,50.77,10.69,76.93-1.83,22.07-8,41-15.83,48.12a6.63,6.63,0,0,1-4.72,2Z'
        );
    }
    30% {
        d: path(
            'M446.52,544.48c-5.45,0-13.44-5.92-21.16-11.65-10.38-7.7-22.14-16.42-35.12-17.42-21.63-1.66-31.42-16.2-37.53-28.68-7.19-14.69-5.12-50.4-3.46-79.09.72-12.39,1.4-24.09,1.4-33.8,0-27.63.26-51.78,3.39-67.59,3.49-17.66,9.51-20,14.93-20,36.74,0,64.69,72.39,87.16,130.53l.26.68c9.06,23.45,12.85,50.77,10.69,76.93-1.83,22.07-8,41-15.83,48.12a6.63,6.63,0,0,1-4.72,2Z'
        );
    }
    100% {
        d: path('');
    }
}

.lungs-animate-left {
    animation: lung-left 2s infinite cubic-bezier(0.8, 0, 0.2, 1.2);
    animation-fill-mode: forwards;
}

.lungs-animate-right {
    animation: lung-right 2s infinite cubic-bezier(0.8, 0, 0.2, 1.2);
    animation-fill-mode: forwards;
}

.lungs-fill-left {
    animation: fill-left 2s infinite cubic-bezier(0.8, 0, 0.2, 1.2);
    animation-fill-mode: forwards;
}

.lungs-fill-right {
    animation: fill-right 2s infinite cubic-bezier(0.8, 0, 0.2, 1.2);
    animation-fill-mode: forwards;
}
