@keyframes colorBlend {
    0% {
        fill: #435a6b;
    }
    50% {
        fill: #b10000;
    }
    100% {
        fill: #435a6b;
    }
}

@keyframes colorBlendLight {
    0% {
        fill: #96ace1;
    }
    50% {
        fill: #f1b991;
    }
    100% {
        fill: #96ace1;
    }
}

.blend-animation {
    animation: colorBlend infinite 1.6s;
}

.blend-animation-light {
    animation: colorBlendLight infinite 1.6s;
}
