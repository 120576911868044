@keyframes arrow {
    0%,
    20%,
    50%,
    80% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(30px);
        opacity: 1;
    }
}

.arrow-animate {
    animation: arrow 400ms linear infinite;
}
