@keyframes test {
    20% {
        width: calc(50%);
    }
    25% {
        width: calc(60%);
    }
    30% {
        width: calc(50%);
    }
}

.switch-button {
    transition: all 0.3s ease-in-out;
    width: 37%;
}

.button-container {
    transition: all 0.3s ease-in-out;
}
.active-container {
    background-color: #30d026;
}

.active-button {
    transform: translateX(175%);
    animation: test 0.3s;
}
